<template>
  <div class="">
    <div class="DetailBox">
      <div class="boxItem">
        <div class="render">
          <div
            id="container"
            :class="downLoad ? 'downLoadrender3d' : 'render3d'"
            @mouseenter="enter()"
            @mouseleave="leave()"
            @mousedown="mousedown($event)"
            @mouseup="mouseup($event)"
            @contextmenu.prevent=""
            @click="
              meshVisible = false;
              yarnVisible = false;
            "
            @touchstart="touchStart"
            @touchmove="touchMove"
            @touchend="touchEnd"
          ></div>
        </div>
      </div>
      <div class="cloth2d boxItem" v-if="!downLoad && !jacquar">
        <div class="Img">
          <div
            v-if="DetailActive == 1 && showImg"
            :style="getFrontUrlMap()"
          ></div>
          <div
            v-if="DetailActive != 1 && showImg"
            :style="getBackUrlMap()"
          ></div>
          <!--  <img
            :src="
              DetailActive == 1
                ? copyDesignResultPath.colorFront
                : copyDesignResultPath.colorBack
            "
            alt=""
            :style="{
              width: sizeNum * 100 + '%',
              height: sizeNum == 1 ? '98%' : sizeNum * 100 + '%',
            }"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import elementResize from "element-resize-detector";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { getresult } from "@/api/index/index";

export default {
  components: {
    // MaskPage,
  },
  props: [
    "id",
    "gender",
    "modelCount",
    "downLoad",
    "jacquar",
    "sideType",
    "FDrenderType",
    "DetailActive",
  ],
  data() {
    return {
      //3d场景数据
      base64: null,
      show3D: true, //判断3d显示
      currentX: 0, //监听滑动距离
      currentY: 0, //监听滑动距离
      scene: new THREE.Scene(), //声明场景
      element: null, //接收标签
      stats: new Stats(), //
      requestAnimationFrameId: null,
      group: new THREE.Group(), //人模父标签，方便操作 卸载 优化性能
      HumanMmodel: "",
      Model: "",
      carSeat: "",
      //3d场景数据

      //页面数据
      copyDesignResultPath: "",
      colorList: [],
      conType: "a",
      diagramType: 0,
      detailDataActive: "a",
      openBtntype: 0,
      sexType: 0,
      modelActive: 0,
      // modelType: "a",
      imgType: 0,
      // DetailActive: 1,
      DetailActive3D: 1,
      btnActive: 0,
      renderType: 0,
      imgActive: 1,
      sizeNum: 1,
      specialType: 0,
      //页面数据
      modelData: [
        {
          modelUrl: "../model/SOCKS/03/socks_03.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS/02/socks_02.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS/01/socks_01.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS/04/socks_04.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS_2/03/socks_03.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS_2/02/socks_02.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS_2/01/socks_01.obj",
          model: "",
        },
        {
          modelUrl: "../model/SOCKS_2/04/socks_04.obj",
          model: "",
        },
      ],
      //模型数据
      sizeRepeatX: 1,
      sizeRepeatY: 1,
      imgWidth: (1024 * 60) / 2.5, //2.5cm = 40成圈 = 1024px  2D面料60cm
      imgHeight: (1024 * 60) / 2.5,
      modelSize: 1, //3D模型60*0.5=30cm,
      showImg: false,
      loadModelType: false,
      resModelIndex: {},
      loadModelEnd: true,
      // modelType: true,
      recordmodelIndex: 2,
      // 判断服装是否有结构图
      jacketStructure: false,

      // 左右
      startX: 0, //开始触摸的位置
      moveX: 0, //滑动时的位置
      endX: 0, //结束触摸的位置
      disX: 0, //移动距离
      // 上下
      startY: 0, //开始触摸的位置
      moveY: 0, //滑动时的位置
      endY: 0, //结束触摸的位置
      disY: 0, //移动距离
      // 双指进行缩放
      startX1: 0, //开始触摸的位置
      moveX1: 0, //滑动时的位置
      endX1: 0, //结束触摸的位置
      startY1: 0, //开始触摸的位置
      moveY1: 0, //滑动时的位置
      endY1: 0, //结束触摸的位置
      startX2: 0, //开始触摸的位置
      moveX2: 0, //滑动时的位置
      endX2: 0, //结束触摸的位置
      startY2: 0, //开始触摸的位置
      moveY2: 0, //滑动时的位置
      endY2: 0, //结束触摸的位置
      dis1: 0, //移动前两指距离
      dis2: 0, //移动后两指距离

      repeatType: 1, //0:完全平铺，1：循环平铺
      colorW: 0, //渲染图宽度，
      colorH: 0, //渲染图高度
      a: 0,
    };
  },
  watch: {
    resModelIndex: {
      handler(newVal) {
        if (this.loadModelType) {
          if (this.modelData[this.modelActive].model != "") {
            this.group.remove(this.modelData[this.modelActive].model);
          } else {
            return;
          }
          this.modelActive = newVal.index;
          if (this.modelData[newVal.index].model != "") {
            this.group.add(this.modelData[newVal.index].model);
            setTimeout(() => {
              this.renderer.render(this.scene, this.camera);
            }, 100);
            // this.hideModel();
          } else {
            this.loadObj(this.modelData[newVal.index].modelUrl);
            this.loadModelEnd = false;
          }
        } else {
          getresult({ designId: this.id }).then((res) => {
            if (res.code == 0) {
              if (
                this.$route.name != "thhistoryDetail" &&
                this.$route.name != "thdownloadpage"
              ) {
                return;
              }
              this.copyDesignResultPath =
                res.data.designResult.designResultPath;
              let type = 0;
              let img = new Image();
              img.src = this.copyDesignResultPath.colorFront;
              img.crossOrigin = "Anonymous";
              img.onload = () => {
                if (img.height != img.width) {
                  let canvas1 = document.createElement("canvas");
                  canvas1.width = 6830;
                  canvas1.height = 6830;
                  let ctx = canvas1.getContext("2d", {
                    willReadFrequently: true,
                  });
                  ctx.fillStyle = "rgba(255,255,255,1)";
                  ctx.fillRect(0, 0, 6830, 6830);
                  ctx.drawImage(
                    img,
                    0,
                    6830 - img.height,
                    img.width,
                    img.height
                  );
                  this.copyDesignResultPath.colorFront =
                    canvas1.toDataURL("image/png");
                  type++;
                  if (type == 2) {
                    this.firstModel(newVal, res);
                  }
                }
              };

              let img1 = new Image();
              img1.src = this.copyDesignResultPath.normalFront;
              img1.crossOrigin = "Anonymous";
              img1.onload = () => {
                if (img1.height != img1.width) {
                  let canvas1 = document.createElement("canvas");
                  let ctx = canvas1.getContext("2d", {
                    willReadFrequently: true,
                  });
                  canvas1.width = 6830;
                  canvas1.height = 6830;
                  ctx.fillStyle = "rgba(255,255,255,1)";
                  ctx.fillRect(0, 0, 6830, 6830);
                  ctx.drawImage(
                    img1,
                    0,
                    6830 - img1.height,
                    img1.width,
                    img1.height
                  );
                  this.copyDesignResultPath.normalFront =
                    canvas1.toDataURL("image/png");
                  type++;
                  if (type == 2) {
                    this.firstModel(newVal, res);
                  }
                }
              };
            }
          });
        }
      },
    },
  },
  beforeDestroy() {
    //将每个模型释放
    this.group.traverse(function (v) {
      if (v.type === "Mesh") {
        v.geometry.dispose();
        if (v.material.length > 0) {
          for (var i = 0; i < v.material.length; i++) {
            v.material[i].dispose();
          }
        } else {
          v.material.dispose();
        }
      }
      v = null;
    });
    this.scene.remove(this.group);
    if (this.renderer) {
      this.renderer.forceContextLoss();
      this.renderer.dispose();
      this.renderer.domElement = null;
      this.renderer = null;
    }
    // this.scene.clear();
    this.scene = null;
    this.camera = null;

    THREE.Cache.clear();
  },
  methods: {
    firstModel(newVal, res) {
      this.modelActive = newVal.index;
      this.colorW =
        (res.data.designResult.designConfig.meanWork.width / 20) * 2.5;
      this.colorH =
        (res.data.designResult.designConfig.meanWork.height / 20) * 2.5;
      this.modelActive = newVal.index;
      this.repeatType = res.data.designResult.designConfig.subimtType - 1;
      this.$nextTick(() => {
        this.render3D(this.modelData[this.modelActive].modelUrl);
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      return flag;
    },
    //获取结构页面传参传参
    setMODEL(data) {
      this.resModelIndex = data;
    },
    setAPairModel(data) {
      this.modelData[data.index].model.rotation.set(0, 0, 0);
      if (data.type == 2) {
        this.group.remove(this.modelData[data.index].model);
        this.modelData[data.index + 4].model.rotation.set(0, 0, 0);
        // 克隆模型
        //短筒袜
        if (data.index == 0) {
          this.modelData[data.index].model.scale.set(1.5, 1.5, 1.5);
          this.modelData[data.index].model.position.set(-5, -20, 0);
          this.modelData[data.index + 4].model.scale.set(1.5, 1.5, 1.5);
          this.modelData[data.index + 4].model.position.set(5, -20, 0);
        }
        //中筒袜
        else if (data.index == 1) {
          this.modelData[data.index].model.scale.set(1, 1, 1);
          this.modelData[data.index].model.position.set(-5, -20, 0);
          this.modelData[data.index + 4].model.scale.set(1, 1, 1);
          this.modelData[data.index + 4].model.position.set(5, -20, 0);
        }
        //长筒袜
        else if (data.index == 2) {
          this.modelData[data.index].model.scale.set(0.6, 0.6, 0.6);
          this.modelData[data.index].model.position.set(-5, -20, 0);
          this.modelData[data.index + 4].model.scale.set(0.6, 0.6, 0.6);
          this.modelData[data.index + 4].model.position.set(5, -20, 0);
        }

        //船袜
        else if (data.index == 3) {
          this.modelData[data.index].model.scale.set(1.8, 1.8, 1.8);
          this.modelData[data.index].model.position.set(-5, -20, 0);
          this.modelData[data.index + 4].model.scale.set(1.8, 1.8, 1.8);
          this.modelData[data.index + 4].model.position.set(5, -20, 0);
        }
        this.modelData[data.index + 4].model.rotateY(Math.PI);
        this.group.add(this.modelData[data.index].model);
        this.group.add(this.modelData[data.index + 4].model);
      } else {
        this.group.remove(this.modelData[data.index].model);
        this.group.remove(this.modelData[data.index + 4].model);
        this.modelData[data.index].model.rotateY(-Math.PI / 2);
        if (data.type == 3) {
          //短筒袜
          if (data.index == 0) {
            this.modelData[data.index].model.scale.set(2.5, 2.5, 2.5);
            this.modelData[data.index].model.position.set(-5, -35, 10);
          }
          //中筒袜
          else if (data.index == 1) {
            this.modelData[data.index].model.scale.set(2, 2, 2);
            this.modelData[data.index].model.position.set(-5, -35, 10);
          }
          //长筒袜
          else if (data.index == 2) {
            this.modelData[data.index].model.scale.set(1, 1, 1);
            this.modelData[data.index].model.position.set(-5, -30, 10);
          }
          //船袜
          else if (data.index == 3) {
            this.modelData[data.index].model.scale.set(3, 3, 3);
            this.modelData[data.index].model.position.set(-5, -30, 10);
          }
        } else {

          //短筒袜
          if (data.index == 0) {
            this.modelData[data.index].model.scale.set(1.8, 1.8, 1.8);
            this.modelData[data.index].model.position.set(-5, -25, 10);
          }
          //中筒袜
          else if (data.index == 1) {
            this.modelData[data.index].model.scale.set(1.5, 1.5, 1.5);
            this.modelData[data.index].model.position.set(-5, -30, 10);
          }
          //长筒袜
          else if (data.index == 2) {
            this.modelData[data.index].model.scale.set(0.8, 0.8, 0.8);
            this.modelData[data.index].model.position.set(-5, -25, 10);
          }
          //船袜
          else if (data.index == 3) {
            this.modelData[data.index].model.scale.set(2, 2, 2);
            this.modelData[data.index].model.position.set(-5, -20, 10);
          }
        }
        this.group.add(this.modelData[data.index].model);
      }
      this.hideModel(2);
      this.renderer.render(this.scene, this.camera);
    },
    // 修改背景
    setSceneBg(value) {
      if (value == 1) {
        this.scene.background = new THREE.Color(0x2c2d31); //暗
      } else {
        this.scene.background = new THREE.Color(0xc6c7cd); //生产场景背景 明
      }
      this.renderer.render(this.scene, this.camera);
    },
    //还原模型
    hideModel(type) {
      this.group.rotation.set(0, 0, 0);
      if (type == 1) {
        this.camera.setFocalLength(25);
      } else {
        this.camera.setFocalLength(30);
      }
    },

    // 渲染3d
    render3D(url) {
      this.initData(); //生成场景
      this.initRender();
      this.initCamera();
      this.initLight(); //灯光调节

      this.onElementResize(); //渲染大小
      this.animate();
      if (this.renderer) {
        this.renderer.render(this.scene, this.camera);
        setTimeout(() => {
          this.loadObj(url); //渲染衣服和几何体
        }, 100);
      }
    },

    initData() {
      //创建场景
      this.element = document.getElementById("container"); //获取场景标签
      if (this.scene) {
        this.scene.background = new THREE.Color(0x2c2d31); //生产场景背景
        this.scene.add(this.group); //加入场景
      }
      // this.scene.background = new THREE.TextureLoader().load(
      //   "../model/newBg.png"
      // ); //生产场景背景
    },

    initRender() {
      let renderer = new THREE.WebGLRenderer({
        antialias: true,
        logarithmicDepthBuffer: false,
        preserveDrawingBuffer: true,
      }); //antialias: true 防锯齿  logarithmicDepthBuffer: true 写入深度的buffer
      renderer.setPixelRatio(window.devicePixelRatio);
      if (this.element) {
        renderer.setSize(
          this.element.clientWidth,
          this.element.clientHeight,
          false
        );
        // renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.shadowMap.enabled = true;
        // renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.element.appendChild(renderer.domElement);
        this.renderer = renderer;
      }
    },

    initCamera() {
      if (this.element) {
        let camera = new THREE.PerspectiveCamera(
          45,
          this.element.clientWidth / this.element.clientHeight,
          0.1,
          1000
        );
        camera.position.set(0, 0, 100);
        camera.up.set(0, 1, 0);
        camera.lookAt(0, 0, 0);
        this.camera = camera;
      }
    },

    animate() {
      // this.stats.update();
      // this.requestAnimationFrameId = requestAnimationFrame(this.animate);
      // this.renderer.render(this.scene, this.camera);
    },

    initLight() {
      // 正面灯光强度
      let directionalLight9 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight9.position.set(0, 0, 1);
      directionalLight9.castShadow = false;
      directionalLight9.shadow.mapSize.width = 2048;
      directionalLight9.shadow.mapSize.height = 2048;
      directionalLight9.shadow.camera.near = 0.5;
      directionalLight9.shadow.camera.far = 1000;
      directionalLight9.shadow.camera.fov = 30;
      // 正左侧面灯光强度
      let directionalLight92 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight92.position.set(1, 0, 1);
      directionalLight92.castShadow = false;
      directionalLight92.shadow.mapSize.width = 2048;
      directionalLight92.shadow.mapSize.height = 2048;
      directionalLight92.shadow.camera.near = 0.5;
      directionalLight92.shadow.camera.far = 1000;
      directionalLight92.shadow.camera.fov = 30;
      // 正右侧面灯光强度
      let directionalLight93 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight93.position.set(-1, 0, 1);
      directionalLight93.castShadow = false;
      directionalLight93.shadow.mapSize.width = 2048;
      directionalLight93.shadow.mapSize.height = 2048;
      directionalLight93.shadow.camera.near = 0.5;
      directionalLight93.shadow.camera.far = 1000;
      directionalLight93.shadow.camera.fov = 30;
      // 后左侧面灯光强度
      let directionalLight94 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight94.position.set(1, 0, -1);
      directionalLight94.castShadow = false;
      directionalLight94.shadow.mapSize.width = 2048;
      directionalLight94.shadow.mapSize.height = 2048;
      directionalLight94.shadow.camera.near = 0.5;
      directionalLight94.shadow.camera.far = 1000;
      directionalLight94.shadow.camera.fov = 30;
      // 后右侧面灯光强度
      let directionalLight95 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight95.position.set(-1, 0, -1);
      directionalLight95.castShadow = false;
      directionalLight95.shadow.mapSize.width = 2048;
      directionalLight95.shadow.mapSize.height = 2048;
      directionalLight95.shadow.camera.near = 0.5;
      directionalLight95.shadow.camera.far = 1000;
      directionalLight95.shadow.camera.fov = 30;
      // 背面灯光强度
      let directionalLight91 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight91.position.set(0, 0, -1);
      directionalLight91.castShadow = false;
      directionalLight91.shadow.mapSize.width = 2048;
      directionalLight91.shadow.mapSize.height = 2048;
      directionalLight91.shadow.camera.near = 0.5;
      directionalLight91.shadow.camera.far = 1000;
      directionalLight91.shadow.camera.fov = 30;

      // 顶部灯光强度
      let directionalLight10 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight10.position.set(0, 1, 0);
      directionalLight10.castShadow = false;
      directionalLight10.shadow.mapSize.width = 2048;
      directionalLight10.shadow.mapSize.height = 2048;
      directionalLight10.shadow.camera.near = 0.5;
      directionalLight10.shadow.camera.far = 1000;
      directionalLight10.shadow.camera.fov = 30;

      let ambientLight2 = new THREE.AmbientLight(0xffffff, 1); //环境光
      this.ambientLight = ambientLight2;
      if (this.scene) {
        // this.scene.add(ambientLight2);
        this.scene.add(directionalLight9);
        this.scene.add(directionalLight91);
        this.scene.add(directionalLight92);
        this.scene.add(directionalLight93);
        this.scene.add(directionalLight94);
        this.scene.add(directionalLight95);
        this.scene.add(directionalLight10);
      }
    },

    loadObj(modelUrl) {
      //加载衣服或几何体
      this.initCamera();
      let manager = new THREE.LoadingManager();
      let objLoader = new OBJLoader(manager);
      const mtlLoader = new MTLLoader(manager);
      let that = this;
      let url = this.copyDesignResultPath.colorFront;
      // let url = "../model/SOCKS/sock_MAP/4.9_COL-white.jpg";
      // let url = "../model/SOCKS/sock_MAP/Grey_Rib_COL.jpg";
      // let url2 = this.copyDesignResultPath.colorBack;
      let url3 = this.copyDesignResultPath.normalFront;
      // let url3 = "../model/SOCKS/sock_MAP/4.9_NRM-ps ok.jpg";
      // let url3 = "../model/SOCKS/sock_MAP/Grey_Rib_NRM.jpg";
      let url4 = this.copyDesignResultPath.normalBack;
      objLoader.load(
        modelUrl,
        function (xhr) {
          let geometry = xhr;
          let color = "";
          let color2 = "";
          let normal1 = "";
          let normal2 = "";
          let count = 0;
          let loader = new THREE.TextureLoader();

          loader.load(url, function (tex) {
            color = tex;
            count++;
            if (count == 2) {
              that.loadModel(color, color2, normal1, normal2, geometry, that);
            }
          });

          /* loader.load(url2, function (tex) {
            color2 = tex;
            count++;
            if (count == 4) {
              that.loadModel(color, color2, normal1, normal2, geometry, that);
            }
          }); */

          loader.load(url3, function (tex) {
            normal1 = tex;
            count++;
            if (count == 2) {
              that.loadModel(color, color2, normal1, normal2, geometry, that);
            }
          });

          /* loader.load(url4, function (tex) {
            normal2 = tex;
            count++;
            if (count == 4) {
              that.loadModel(color, color2, normal1, normal2, geometry, that);
            }
          }); */
        },
        function (xhr) {
          // console.log('onProgress: ' + xhr);
        },
        function (xhr) {
          // console.log('onError:' + xhr);
        }
      );
    },

    loadModel(color, color2, normal1, normal2, geometry, that) {
      let repeatX = that.imgWidth / that.colorW;
      let repeatY = that.imgHeight / color.image.height;
      that.sizeRepeatX = repeatX * that.modelSize;
      that.sizeRepeatY = repeatY * that.modelSize;
      that.showImg = true;

      color.repeat = new THREE.Vector2(1, 1);
      normal1.repeat = new THREE.Vector2(1, 1);
      // color2.repeat = new THREE.Vector2(1, 1);
      // normal2.repeat = new THREE.Vector2(1, 1);
      color.wrapS = color.wrapT = THREE.repeatWrapping;
      // color2.wrapS = color2.wrapT = THREE.repeatWrapping;
      // color2.encoding = THREE.sRGBEncoding;
      normal1.wrapS = normal1.wrapT = THREE.repeatWrapping;
      // normal2.wrapS = normal2.wrapT = THREE.repeatWrapping;

      let material = new THREE.MeshStandardMaterial({
        map: color,
        normalMap: normal1,
        side: THREE.DoubleSide,
      });

      let material5 = new THREE.MeshStandardMaterial({
        map: color,
        normalMap: normal1,
        side: THREE.DoubleSide,
      });

      //短筒袜
      if (this.modelActive == 0) {
        geometry.children[0].material = material5;
        geometry.scale.set(2.5, 2.5, 2.5);
        geometry.position.set(-5, -35, 10);
      }

      //中筒袜
      else if (this.modelActive == 1) {
        geometry.children[0].material = material5;
        geometry.scale.set(2, 2, 2);
        geometry.position.set(-5, -35, 10);
      }
      //长筒袜
      else if (this.modelActive == 2) {
        geometry.children[0].material = material5;
        geometry.scale.set(1, 1, 1);
        geometry.position.set(-5, -30, 10);
      }

      //船袜
      else if (this.modelActive == 3) {
        geometry.children[0].material = material5;
        geometry.scale.set(2.5, 2.5, 2.5);
        geometry.position.set(-5, -25, 10);
      }
      that.camera.setFocalLength(42);
      geometry.rotateY(-Math.PI / 2);
      that.group.add(geometry);
      that.group.rotation.set(0, 0, 0);
      that.group.position.set(0, 0, 0);
      that.modelData[that.modelActive].model = geometry;
      that.modelData[that.modelActive + 4].model = geometry.children[0].clone();
      that.loadModelEnd = true;
      setTimeout(() => {
        if (that.renderer) {
          that.renderer.render(that.scene, that.camera);
        }
        that.loadModelType = true;
        if (that.downLoad) {
          that.$emit("loadModelSuccess");
        }
      }, 10);
    },

    setmodelSize(type) {
      if (type == 1) {
        this.group.scale.set(1.1, 1.1, 1.1);
      } else {
        this.group.scale.set(1.5, 1.5, 1.5);
      }
    },

    onElementResize() {
      //渲染场景大小
      if (this.element) {
        let erd = elementResize();
        if (this.element._erd) {
          this.element._erd = null;
        }
        erd.listenTo(this.element, (element) => {
          this.camera.aspect = element.clientWidth / element.clientHeight;
          this.camera.updateProjectionMatrix();
          this.renderer.setSize(
            element.clientWidth,
            element.clientHeight,
            true
          );
          this.renderer.render(this.scene, this.camera);
        });
      }
    },

    // 渲染3d

    // -----  3d的操作方法 -----
    enter() {
      if (this._isMobile()) {
        return;
      }
      //引入3D
      document.addEventListener("mousewheel", this.mousewheelHandler, {
        passive: false,
      });
      document.addEventListener("mousewheel", this.handleScroll, true);
    },

    leave() {
      if (this._isMobile()) {
        return;
      }
      //鼠标离开时移出监听
      document.removeEventListener("mousewheel", this.mousewheelHandler, {
        passive: false,
      });
      document.removeEventListener("mousewheel", this.handleScroll, true);
    },

    mousewheelHandler(e) {
      // if (this.modelActive == 20) {
      //   return;
      // }
      //禁止页面对滚动条监听
      e.preventDefault();
    },

    handleScroll(e) {
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      //使用滚轮进行缩放
      if (
        e.deltaY < 0 &&
        this.camera != null &&
        this.camera.getFocalLength() < 300
      ) {
        this.camera.setFocalLength(this.camera.getFocalLength() + 2);
      }
      if (
        e.deltaY > 0 &&
        this.camera != null &&
        this.camera.getFocalLength() > 20
      ) {
        this.camera.setFocalLength(this.camera.getFocalLength() - 2);
      }

      this.renderer.render(this.scene, this.camera);
    },

    mousedown(event) {
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      if (this._isMobile()) {
        return;
      }
      //监听鼠标按下移动3d
      this.currentX = event.screenX;
      this.currentY = event.screenY;

      if (event.button === 0 && !window.event.ctrlKey) {
        document.addEventListener("mousemove", this.rotate);
      }
      if (event.button === 0 && window.event.ctrlKey) {
        document.addEventListener("mousemove", this.move);
      }
    },

    mouseup(event) {
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      if (this._isMobile()) {
        return;
      }
      //鼠标抬起取消监听
      document.removeEventListener("mousemove", this.move);
      document.removeEventListener("mousemove", this.rotate);
    },

    move(event) {
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      //监听上下移动ctrl+鼠标
      let vec = this.group.position;
      let offsetX = event.screenX - this.currentX;
      let offsetY = event.screenY - this.currentY;
      // this.currentY = event.screenY;

      if (offsetX > 2 && vec.x < 50) {
        this.currentX = event.screenX;
        this.group.position.set(vec.x + offsetX / 4, vec.y, vec.z);
      }
      if (offsetX < -2 && vec.x > -50) {
        this.currentX = event.screenX;
        this.group.position.set(vec.x + offsetX / 4, vec.y, vec.z);
      }
      if (offsetY < -2 && vec.y < 50) {
        this.currentY = event.screenY;
        this.group.position.set(vec.x, vec.y - offsetY / 4, vec.z);
      }
      if (offsetY > 2 && vec.y > -50) {
        this.currentY = event.screenY;
        this.group.position.set(vec.x, vec.y - offsetY / 4, vec.z);
      }

      this.renderer.render(this.scene, this.camera);
    },

    rotate(event) {
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      //监听3d旋转
      let vec = this.group.rotation;
      let offsetX = event.screenX - this.currentX;
      let offsetY = event.screenY - this.currentY;
      this.currentY = event.screenY;
      let xUnit = new THREE.Vector3(1, 0, 0);
      let yUnit = new THREE.Vector3(0, 1, 0);
      let quat = new THREE.Quaternion();
      if (offsetX > 1) {
        this.currentX = event.screenX;
        quat.setFromAxisAngle(yUnit, 0.1);
        this.group.rotateY(Math.PI / 18);
      }
      if (offsetX < -1) {
        this.currentX = event.screenX;
        quat.setFromAxisAngle(yUnit, -0.1);
        this.group.rotateY(Math.PI / -18);
      }
      if (offsetY < -1 && vec.y < 5) {
        this.currentY = event.screenY;
        quat.setFromAxisAngle(xUnit, -0.1);
        this.group.quaternion.multiplyQuaternions(quat, this.group.quaternion);
      }
      if (offsetY > 1 && vec.y > -15) {
        this.currentY = event.screenY;
        quat.setFromAxisAngle(xUnit, 0.1);
        this.group.quaternion.multiplyQuaternions(quat, this.group.quaternion);
      }
      this.renderer.render(this.scene, this.camera);
    },
    // -----  3d的操作方法 -----

    getFrontUrlMap() {
      return {
        "background-image":
          'url("' + this.copyDesignResultPath.colorFront + '")',
        width: this.sizeNum * 100 + "%",
        height: this.sizeNum == 1 ? "98%" : this.sizeNum * 100 + "%",
        "background-size": (this.sizeNum * 100) / this.sizeRepeatX + "%",
      };
    },
    getBackUrlMap() {
      return {
        "background-image":
          'url("' + this.copyDesignResultPath.colorBack + '")',
        width: this.sizeNum * 100 + "%",
        height: this.sizeNum == 1 ? "98%" : this.sizeNum * 100 + "%",
        "background-size": (this.sizeNum * 100) / this.sizeRepeatX + "%",
      };
    },

    touchStart(ev) {
      /* if (!this._isMobile()) {
        return;
      } */
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        //tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
        this.startX = ev.touches[0].clientX; // 记录开始位置
        this.startY = ev.touches[0].clientY;
      }

      if (ev.touches.length > 1) {
        //tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
        this.startX1 = ev.touches[0].clientX; // 记录开始位置
        this.startY1 = ev.touches[0].clientY;

        this.startX2 = ev.touches[1].clientX; // 记录开始位置
        this.startY2 = ev.touches[1].clientY;
      }
    },
    touchMove(ev) {
      /*  if (!this._isMobile()) {
        return;
      } */
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      ev = ev || event;
      ev.preventDefault();

      if (ev.touches.length == 1) {
        //滑动时距离浏览器左侧的距离
        this.moveX = ev.touches[0].clientX;
        this.moveY = ev.touches[0].clientY;
        //实时的滑动的距离-起始位置=实时移动的位置
        this.disX = this.moveX - this.startX;
        this.disY = this.moveY - this.startY;
        let xUnit = new THREE.Vector3(1, 0, 0);
        let yUnit = new THREE.Vector3(0, 1, 0);
        let quat = new THREE.Quaternion();
        let vec = this.group.rotation;
        // 左右旋转
        if (this.disX < -1) {
          this.startX = ev.touches[0].clientX;
          quat.setFromAxisAngle(yUnit, -0.1);
          this.group.rotateY(Math.PI / -60);
        } else if (this.disX > 1) {
          this.startX = ev.touches[0].clientX;
          quat.setFromAxisAngle(yUnit, -0.1);
          this.group.rotateY(Math.PI / 60);
        }

        if (this.disY < -1 && vec.y < 5) {
          this.startY = ev.touches[0].clientY;
          quat.setFromAxisAngle(xUnit, -0.1);
          this.group.quaternion.multiplyQuaternions(
            quat,
            this.group.quaternion
          );
        }
        if (this.disY > 1 && vec.y > -15) {
          this.startY = ev.touches[0].clientY;
          quat.setFromAxisAngle(xUnit, 0.1);
          this.group.quaternion.multiplyQuaternions(
            quat,
            this.group.quaternion
          );
        }
      }

      // 缩放
      if (ev.touches.length > 1) {
        this.moveX1 = ev.touches[0].clientX;
        this.moveY1 = ev.touches[0].clientY;

        this.moveX2 = ev.touches[1].clientX;
        this.moveY2 = ev.touches[1].clientY;

        // 移动
        let offsetX =
          (this.moveX1 + this.moveX2) / 2 - (this.startX1 + this.startX2) / 2;
        let offsetY =
          (this.moveY1 + this.moveY2) / 2 - (this.startY1 + this.startY2) / 2;

        //实时的滑动的距离-起始位置=实时移动的位置
        this.dis1 = Math.sqrt(
          Math.pow(this.startX1 - this.startX2, 2) +
            Math.pow(this.startY1 - this.startY2, 2)
        );
        this.dis2 = Math.sqrt(
          Math.pow(this.moveX1 - this.moveX2, 2) +
            Math.pow(this.moveY1 - this.moveY2, 2)
        );

        if (
          this.dis1 < this.dis2 - 2 &&
          this.camera != null &&
          this.camera.getFocalLength() < 300
        ) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.camera.setFocalLength(this.camera.getFocalLength() + 2);
        }
        if (
          this.dis1 - 2 > this.dis2 &&
          this.camera != null &&
          this.camera.getFocalLength() > 20
        ) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.camera.setFocalLength(this.camera.getFocalLength() - 2);
        }

        // 移动判断
        // 向左移动
        let vec = this.group.position;

        if (offsetX - 2 > 0 && this.group.position.x < 30) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.group.position.set(vec.x + 0.5, vec.y, vec.z);
        }
        // 向右移动
        if (offsetX + 2 < 0 && this.group.position.x > -30) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.group.position.set(vec.x - 0.5, vec.y, vec.z);
        }
        // 向上移动
        if (offsetY - 2 > 0 && this.group.position.y > -30) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.group.position.set(vec.x, vec.y - 0.5, vec.z);
        }
        // 向下移动
        if (offsetY + 2 < 0 && this.group.position.y < 40) {
          this.startX1 = ev.touches[0].clientX; // 记录开始位置
          this.startY1 = ev.touches[0].clientY;

          this.startX2 = ev.touches[1].clientX; // 记录开始位置
          this.startY2 = ev.touches[1].clientY;

          this.group.position.set(vec.x, vec.y + 0.5, vec.z);
        }
      }
    },
    touchEnd(ev) {
      /* if (!this._isMobile()) {
        return;
      } */
      if (this.modelData[this.modelActive].model == "") {
        return;
      }
      ev = ev || event;
      ev.preventDefault();

      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;
        this.disX = endX - this.startX;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./Render.less");
@import url("./size.less");
</style>
</script>
<style lang="less" scoped>
@import url("./Render.less");
@import url("./size.less");
</style>