var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jacquardWeave"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.download),expression:"!download"}],staticClass:"top"},[_c('div',{staticClass:"leftTop"},[_c('span',[_vm._v(" "+_vm._s(!_vm.isDetail ? "花型意匠图" : ""))])]),_c('div',{staticClass:"rightTop"},[_c('div',{staticClass:"decrease",on:{"click":function($event){return _vm.setSize(0)}}},[_c('img',{attrs:{"src":require("@/images/newnarrowBtn@2x.png"),"alt":""}})]),_c('div',{staticClass:"add",on:{"click":function($event){return _vm.setSize(1)}}},[_c('img',{attrs:{"src":require("@/images/newenlargeBtn@2x.png"),"alt":""}})])])]),_c('div',{ref:"jacquardWeaveWork",staticClass:"jacquardWeaveWork",on:{"mouseover":function($event){return _vm.sethiddenScrollbar(true)},"mouseout":function($event){return _vm.sethiddenScrollbar(false)}}},[(_vm.scrollBoxHidden)?_c('vue-custom-scrollbar',{staticClass:"scrollBox",style:(((_vm.meanWorkLists.data.length * parseInt(20 * _vm.sizeNum) > _vm.scrollBoxHeight
          ? 'align-items:baseline;'
          : '') + "\n      " + (_vm.meanWorkLists.data[0].length * parseInt(20 * _vm.sizeNum) > _vm.scrollBoxWidth
          ? 'justify-content:normal;'
          : ''))),attrs:{"settings":_vm.settings}},[(_vm.TableHidden)?_c('div',{ref:"table",staticClass:"Table",style:(((this.sizeNum <= 0.8
            ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.15);border-right: 1px solid rgba(0, 0, 0, 0.15);'
            : '') + "\n        transform: scale(" + _vm.sizeNum + ");\n        "))},_vm._l((_vm.meanWorkLists.data),function(item,index){return _c('div',{key:index,staticClass:"td"},_vm._l((item),function(itenChild,itemIndex){return _c('div',{key:itemIndex,staticClass:"tb"},[_c('div',{staticClass:"item",style:(_vm.getImgSize(
                  _vm.meanWorkLists.data[_vm.meanWorkLists.data.length - index - 1][
                    itemIndex
                  ]
                ))})])}),0)}),0):_vm._e(),(!_vm.TableHidden)?_c('img',{style:(("\n        display:block;\n        width:" + (parseInt((_vm.imgW - 1) * _vm.sizeNum + 1)) + "px;\n        height:" + (parseInt((_vm.imgH - 1) * _vm.sizeNum + 1)) + "px;\n      ")),attrs:{"src":_vm.imgUrl,"alt":""}}):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }