<template>
  <div class="jacquardWeave">
    <div class="top" v-show="!download">
      <div class="leftTop">
        <span> {{ !isDetail ? "花型意匠图" : "" }}</span>
      </div>
      <div class="rightTop">
        <div class="decrease" @click="setSize(0)">
          <img src="@/images/newnarrowBtn@2x.png" alt="" />
        </div>
        <div class="add" @click="setSize(1)">
          <img src="@/images/newenlargeBtn@2x.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="jacquardWeaveWork"
      ref="jacquardWeaveWork"
      @mouseover="sethiddenScrollbar(true)"
      @mouseout="sethiddenScrollbar(false)"
    >
      <vue-custom-scrollbar
        v-if="scrollBoxHidden"
        :settings="settings"
        class="scrollBox"
        :style="`${
          meanWorkLists.data.length * parseInt(20 * sizeNum) > scrollBoxHeight
            ? 'align-items:baseline;'
            : ''
        }
        ${
          meanWorkLists.data[0].length * parseInt(20 * sizeNum) > scrollBoxWidth
            ? 'justify-content:normal;'
            : ''
        }`"
      >
        <div
          class="Table"
          ref="table"
          v-if="TableHidden"
          :style="`${
            this.sizeNum <= 0.8
              ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.15);border-right: 1px solid rgba(0, 0, 0, 0.15);'
              : ''
          }
          transform: scale(${sizeNum});
          `"
        >
          <div
            class="td"
            v-for="(item, index) in meanWorkLists.data"
            :key="index"
          >
            <div
              class="tb"
              v-for="(itenChild, itemIndex) in item"
              :key="itemIndex"
            >
              <div
                class="item"
                :style="
                  getImgSize(
                    meanWorkLists.data[meanWorkLists.data.length - index - 1][
                      itemIndex
                    ]
                  )
                "
              ></div>
            </div>
          </div>
        </div>
        <img
          :src="imgUrl"
          alt=""
          v-if="!TableHidden"
          :style="`
          display:block;
          width:${parseInt((imgW - 1) * sizeNum + 1)}px;
          height:${parseInt((imgH - 1) * sizeNum + 1)}px;
        `"
        />
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import html2canvas from "html2canvas";

export default {
  name: "jacquardWeaveMeanWork",
  props: [
    "meanWorkList",
    "yarngauze",
    "download",
    "isRemainder",
    "isDetail",
    "pageType",
    "getImgType",
  ],
  components: {
    vueCustomScrollbar,
  },
  data() {
    return {
      // 测试
      yarngauzes: [],
      meanWorkLists: {},
      //   缩放大小
      sizeNum: 1,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      scrollBoxHidden: false,
      scrollBoxWidth: 0,
      scrollBoxHeight: 0,
      imgUrl: "",
      TableHidden: true,
      imgW: 0,
      imgH: 0,
      machineType: JSON.parse(localStorage.getItem("machineType")).type,
    };
  },
  methods: {
    //监听滑入滑出
    sethiddenScrollbar(type) {
      if (type != this.hiddenScrollbar) {
        this.hiddenScrollbar = type;
        this.$emit("hiddenScrollbar", type);
      }
    },

    setSize(val) {
      if (val == 0 && this.sizeNum > 0.2) {
        this.sizeNum -= 0.1;
      }
      if (val == 1 && this.sizeNum < 2) {
        this.sizeNum += 0.1;
      }
      this.settings = {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: true,
      };
    },

    getImgSize(val) {
      var num = 0;
      if (this.pageType == 1) {
        num = val;
      } else {
        if (val < 10) {
          num = 0;
        } else {
          num = Math.floor(val / 10);
        }
      }
      if (this.yarngauzes.length > 0) {
        return {
          background: this.isRemainder
            ? this.yarngauzes[num].yarnColor
            : this.yarngauzes[num].yarnColor,
          // width: this.sizeNum * 20 + "px",
          // height: this.sizeNum * 20 + "px",
          "border-right":
            this.sizeNum > 0.8 ? "1px solid rgba(0, 0, 0, 0.15)" : "",
        };
      }
    },
    getTbSize(index) {
      if (this.sizeNum > 0.8) {
        // if (index == this.meanWorkLists.data.length - 1) {
        //   return {
        //     "border-right": "",
        //   };
        // } else {
        // }
        return {
          "border-bottom": "1px solid rgba(0, 0, 0, 0.15)",
        };
      } else {
        return {
          "border-bottom": "",
        };
      }
    },
    setMeanWork() {
      this.$nextTick(() => {
        this.scrollBoxHidden = true;
        this.meanWorkLists = this.meanWorkList;
        this.yarngauzes = this.yarngauze;
        setTimeout(() => {
          this.imgW = this.$refs.table.offsetWidth;
          this.imgH = this.$refs.table.offsetHeight;

          html2canvas(this.$refs.table).then((canvas) => {
            // 转成图片，生成图片地址
            this.imgUrl = canvas.toDataURL("image/png");
            this.TableHidden = false;
            if (this.getImgType == 1) {
              // localStorage.setItem(
              //   "jacquardWeaveMeanWorkImg",
              //   JSON.stringify({
              //     img: this.imgUrl,
              //     imgW: this.imgW,
              //     imgH: this.imgH,
              //   })
              // );
              localStorage.setItem("jacquardWeaveMeanWorkImg", this.imgUrl);
              localStorage.setItem(
                "jacquardWeaveMeanWorkSize",
                JSON.stringify({ imgW: this.imgW, imgH: this.imgH })
              );
            }
          });
        }, 1);
      });
    },
  },
  watch: {
    meanWorkList: {
      //监听提花意匠图
      handler(newVal, oldVal) {
        this.setMeanWork();
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    if (
      localStorage.getItem("jacquardWeaveMeanWorkImg") != null &&
      this.getImgType == 1
    ) {
      this.meanWorkLists = this.meanWorkList;
      this.scrollBoxHidden = true;
      let data = JSON.parse(localStorage.getItem("jacquardWeaveMeanWorkSize"));
      this.imgUrl = localStorage.getItem("jacquardWeaveMeanWorkImg");
      this.imgW = data.imgW;
      this.imgH = data.imgH;
      this.TableHidden = false;
      return;
    }
    this.$nextTick(() => {
      this.scrollBoxWidth = parseInt(this.$refs.jacquardWeaveWork.offsetWidth);
      this.scrollBoxHeight = parseInt(
        this.$refs.jacquardWeaveWork.offsetHeight
      );
      this.setMeanWork();
    });
  },
};
</script>

<style lang="less" scoped>
@import "./jacquardWeaveMeanWork.less";
</style>